import Image from 'components/atoms/Image'
import ReusableGrid from 'components/containers/ReusableGrid'
import Heading from 'components/molecules/Heading'
import problemSolving from 'consts/SolveProblem'
import React from 'react'

const SolveProblems = () => {
  return (
    <ReusableGrid className='rounded-xl p-4 md:!p-16' id='solve-problems'>
      <div className='col-span-full flex items-center text-center'>
        <Heading
          postTitle={problemSolving.title}
          heroHeading={true}
          className='mx-auto text-3xl font-bold md:text-5xl'
        />
      </div>

      <div className='col-span-full grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
        {problemSolving.solutions.map(solution => (
          <div key={solution.id} className='flex flex-col gap-4'>
            <Heading postTitle={solution.heading} className='text-2xl font-semibold' />
            <div className='h-72 w-full overflow-hidden rounded-lg'>
              <Image
                src={solution.image}
                alt={solution.heading}
                width={1600}
                height={900}
                className='h-full w-full object-cover'
              />
            </div>
            <p className='text-justify text-lg font-medium'>{solution.subHeading}</p>
          </div>
        ))}
      </div>
    </ReusableGrid>
  )
}

export default SolveProblems
