// ------------- Products Reducer -------------
import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

// #1 Initial state
const initialState = {
  products: [],
  allProducts: [],
  deletedImages: [],
  isProductFormSubmitted: false,
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => ({ ...state, products: action?.payload || [] }),
    setAllProducts: (state, action) => ({ ...state, allProducts: action?.payload || [] }),
    setIsProductFormSubmitted: (state, action) => ({
      ...state,
      isProductFormSubmitted: action?.payload || false,
    }),
    setDeletedProductImages: (state, action) => ({
      ...state,
      deletedImages: action?.payload || [],
    }),
    //
    resetProducts: () => initialState,
  },
  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     console.log('HYDRATE', state, action.payload)

  //     return {
  //       ...state,
  //       ...action.payload,
  //     }
  //   },
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setProducts,
  setAllProducts,
  resetProducts,
  setIsProductFormSubmitted,
  setDeletedProductImages,
} = productsSlice.actions

export default productsSlice.reducer
