import { auth } from 'services/FirebaseClient'

export const getToken = async () => {
  try {
    const token = await auth.currentUser.getIdToken()

    return token
  } catch (error) {
    console.error('Error @getToken', error)

    // return null
  }
}
