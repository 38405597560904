import ROUTES from 'consts/Routes'

const data = {
  title: "Ready to Rock 'n' Roll?",
  description: 'Get started with SESSAMI.',
  // cta2: {
  //   title: 'Sign Up Now',
  //   href: ROUTES.SIGN_UP,
  //   external: false,
  // },
  cta1: {
    title: 'Create An Account',
    href: ROUTES.SIGN_UP,
    external: false,
  },
}

export default data
