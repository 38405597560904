import ReusableGrid from 'components/containers/ReusableGrid'
import React from 'react'

const Challenges = () => {
  return (
    <ReusableGrid className='card bg-white !shadow-none '>
      <div
        className='hero col-span-full h-fit py-20 backdrop-blur-md'
        // style={{
        //   backgroundImage:
        //     'url(https://daisyui.com/images/stock/photo-1507358522600-9f71e620c44e.jpg)',
        // }}
      >
        {/* <div className='hero-overlay bg-base-200 card !shadow-none'></div> */}
        <div className='!md:max-w-1/2 hero-content !w-full text-center text-primary'>
          <div className=''>
            <h2 className='mb-5 text-3xl font-bold md:text-5xl'>The Problem For Event Managers</h2>
            <p className=' text-xl'>
              Running events isn’t easy. This goes double when you have an event management platform
              that doesn’t do what you want it to. Customers we speak to often have the following
              problems:
              <ol>
                <br />
                <li>(1) We don’t know what advertising works and what doesn’t.</li>
                <li>(2) Our ticketing platform is confusing and it’s expensive.</li>
                <li>(3) There’s no customer service to help me with my issues.</li>
              </ol>
              <br />
              And that, event managers, is how we came to be.
            </p>
            {/* <button className='btn btn-secondary'>Get Started</button> */}
          </div>
        </div>
      </div>
    </ReusableGrid>
  )
}

export default Challenges
