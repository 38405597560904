import ChildLoaderUI from 'components/atoms/ChildLoaderUI'
import React from 'react'
import { useSelector } from 'react-redux'

interface RootLoadingState {
  pr: {
    loading: {
      isLoading: boolean
      message: string
    }
  }
}

const ParentLoaderUI = () => {
  const globalLoading = useSelector((state: RootLoadingState) => state?.pr?.loading) || null

  if (!globalLoading?.isLoading) return null

  return (
    <div
      id='global-loader'
      className='pointer-events-auto fixed inset-0 !z-[999] flex flex-col items-center justify-center gap-2 bg-black bg-opacity-80'>
      <ChildLoaderUI />
      {globalLoading?.message && <span className='text-white'>{globalLoading.message}</span>}
    </div>
  )
}

export default ParentLoaderUI
