import { Moon, Sun } from 'consts/Icons'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTheme } from 'store/reducers'

const ThemeToggler = ({ showLabel = true }) => {
  const dispatch = useDispatch()
  const theme = useSelector(s => s?.pr?.ui?.theme)

  useEffect(() => {
    document.body.setAttribute('data-theme', theme)
  }, [theme])

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    dispatch(setTheme(newTheme))
    document.documentElement.className = theme === 'dark' ? '' : 'dark' // light mode doesn't need any class
  }

  return (
    <div className='flex flex-row items-center' onClick={toggleTheme}>
      <label className='swap'>
        {theme === 'dark' ? (
          <Moon size={24} className='swap-off' />
        ) : (
          <Sun size={24} className='swap-off' />
        )}
      </label>
      {showLabel && (
        <label htmlFor='theme' className='cursor-pointer'>
          {theme === 'dark' ? 'Dark' : 'Light'} Mode
        </label>
      )}
    </div>
  )
}

export default ThemeToggler
