import MSG from 'consts/Messages'
import { toast } from 'react-toastify'

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

const getRequestOptions = (
  body: any,
  method = 'POST',
  redirect = 'follow' as RequestRedirect,
  accessToken = ''
) => {
  if (!body || !method || !redirect) {
    toast.error(MSG.ERR.REQUEST_OPTION_ERR, { toastId: 'operation-error' })

    return
  }

  // const myHeaders = new Headers()
  // myHeaders.append('Content-Type', 'application/json')

  // accessToken && myHeaders.append('Authorization', `Bearer ${accessToken}`)

  const myHeaders = {
    ...DEFAULT_HEADERS,
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  }

  const requestOptions = {
    method,
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect,
  }

  return requestOptions
}

export default getRequestOptions
