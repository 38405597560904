import cn from 'clsx'
import ReusableGrid from 'components/containers/ReusableGrid'
import BackBar from 'components/molecules/BackBar/BackBar'
import ProfileMenu from 'components/molecules/ProfileMenu'
import UnVerifiedBanner from 'components/molecules/UnVerifiedBanner'
import withErrorBoundary from 'components/templates/WithErrorBoundary'
import { HEADER } from 'consts'
import Link from 'next/link'
import React from 'react'
import { useSelector } from 'react-redux'

const Header = () => {
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)

  return (
    <ReusableGrid className='custom-transition-b absolute right-0 top-0 z-40 w-full bg-base-100'>
      <div
        className={cn(
          'col-span-full flex items-center justify-between px-4',
          accessToken ? 'py-2' : 'py-4'
        )}>
        <BackBar />

        <div className='flex items-center justify-center space-x-4'>
          {!accessToken && <HeaderNav links={HEADER.NAVIGATION_CENTER} />}
        </div>

        {accessToken ? (
          <div className='flex items-center justify-center space-x-4'>
            <HeaderNav links={HEADER.APP_HOST_NAVIGATION} />
            <ProfileMenu />
          </div>
        ) : (
          <HeaderNav links={HEADER.NAVIGATION_RIGHT} />
        )}
      </div>
      <UnVerifiedBanner />
    </ReusableGrid>
  )
}

const HeaderNav = ({ links = [] }) => {
  // Handle internal and external links. Internal by default
  const LinkCore = ({ item, parentItem = null }) => {
    const sharedClassName = cn(
      'flex h-full w-full flex-row items-center text-center',
      item?.icon && 'gap-2',
      parentItem?.isQuickAdd && 'btn btn-primary btn-sm mb-1'
    )

    if (item?.externalPath) {
      return (
        <Link href={item.externalPath} target='_blank' rel='noreferrer' className={sharedClassName}>
          {item.icon}
          {item.title}
        </Link>
      )
    }

    if (item?.onClick) {
      return (
        <button onClick={item.onClick} className={sharedClassName}>
          {item.icon}
          {item.title}
        </button>
      )
    }

    return (
      <Link href={item.path || '#'} className={sharedClassName}>
        {item.icon}
        {item.title}
      </Link>
    )
  }

  return (
    <ul className='flex items-center justify-center space-x-2 md:space-x-6'>
      {links.map(item => (
        <li key={item.path} className={item?.className}>
          {item?.subMenu?.length ? (
            <div className='dropdown dropdown-end'>
              <div tabIndex='0' role='button' className='btn btn-ghost btn-sm'>
                {item.title}
                {item.icon}
              </div>
              <ul
                tabIndex='0'
                className='menu dropdown-content z-10 w-52 rounded-box bg-base-200 p-2 text-primary shadow'>
                {item.subMenu.map((subItem, index) => (
                  <li key={subItem.path + index}>
                    {/* <Link href={subItem.path ||"#"} className='flex items-center'>
                        {subItem.title}
                      </Link> */}
                    <LinkCore parentItem={item} item={subItem} />
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <LinkCore parentItem={item} item={item} />
          )}
        </li>
      ))}
    </ul>
  )
}

export default withErrorBoundary(Header)
