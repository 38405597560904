import { EVENT_PLACEHOLDER_IMAGE } from 'consts'
import { SIZING_SUFFIX_MAP } from 'consts/Images'
import { default as NextImage } from 'next/image'
import React from 'react'
import { injectSuffix } from 'utils/Images'

type sizing = 160 | 320 | 480 | 640 | 960 | 1280 | null

interface ImageProps {
  src: string
  alt?: string
  sizing?: sizing
  [propName: string]: any
}

const Image: React.FC<ImageProps> = ({ src, alt, sizing, ...props }) => {
  let source =
    (sizing ? injectSuffix(src, SIZING_SUFFIX_MAP[sizing]) : src) || EVENT_PLACEHOLDER_IMAGE

  const alternateText = alt || 'Sessami'

  if (!source) return null

  return (
    <NextImage {...props} src={source} alt={alternateText} blurDataURL={EVENT_PLACEHOLDER_IMAGE} />
  )
}

export default Image
