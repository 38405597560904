import cn from 'clsx'
import MainContainer from 'components/containers/MainContainer'
import EventCreationViaURLModal from 'components/organisms/EventCreationViaURLModal'
import TopBar from 'components/organisms/nav/Header'
import PWAnavbar from 'components/organisms/nav/MobileNavBar'
import NavDrawer from 'components/organisms/nav/NavDrawer'
import { ROUTES } from 'consts'
import useFetcherForHost from 'hooks/useFetcherForHost'
import useUserDocFetch from 'hooks/useUserDocFetch'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const AuthHostLayout = ({ children }) => {
  const router = useRouter()
  const { userDocFetch } = useUserDocFetch()
  const { fetchAllMyEvents, fetchAllMyEventOrders } = useFetcherForHost()

  const user = useSelector(s => s?.pr?.user?.info)
  const isDrawerOpen = useSelector(s => s?.pr?.ui?.isDrawerOpen)
  const accessToken = useSelector(s => s?.pr?.user?.auth?.stsTokenManager?.accessToken)

  useEffect(() => {
    if (!accessToken) {
      router.replace(ROUTES.ROOT)

      return
    }

    if (user?.id) {
      fetchAllMyEvents()
      fetchAllMyEventOrders()
    } else {
      userDocFetch()
    }
  }, [accessToken, user?.id])

  useEffect(() => {
    if (!user?.id) return // Don't redirect if user is not fetched yet

    if (user?.userType === 'user') {
      router.replace(ROUTES.ONBOARDING)
    }
  }, [user, router.pathname])

  return (
    <>
      <div className='flex flex-row'>
        <NavDrawer />
        <div
          className={cn(
            'custom-transition-a relative',
            'ml-0 flex w-full flex-col',
            'mb-0 md:pb-0', // For Menu Bar on Mobile View (Bottom)
            // 16 for Collapsed View & 52 for Expanded View
            accessToken && (isDrawerOpen ? 'lg:ml-72' : 'lg:ml-16')
          )}>
          <TopBar />
          <MainContainer className='max-h-screen overflow-y-scroll'>{children}</MainContainer>
        </div>
        <PWAnavbar />
      </div>

      <EventCreationViaURLModal />
    </>
  )
}

export default AuthHostLayout
