// import { IoDiamond } from '@react-icons/all-files/io5/IoDiamond'

const Testimonials = {
  title: 'Testimonials',
  testimonials: [
    {
      id: 1,
      heading: 'This site is easy and simple to use',
      description:
        'The site is easy and simple to use. Support are very responsive and helpful too.',
      linkToReview: 'https://www.trustpilot.com/reviews/66bdc0c7c2adb31565f93cc1',
      user: {
        fullName: 'Andy',
        stars: 5,
      },
    },
    {
      id: 3,
      heading: 'A team that cares about your business',
      description:
        'We’ve been using Sessami for over a year now, and they are the perfect ticketing partner.',
      linkToReview: 'https://www.trustpilot.com/reviews/66842720a08ece044c4b9f3d',
      user: {
        fullName: `Suzanne`,
        stars: 5,
      },
    },
    {
      id: 2,
      heading: 'Easy no hassle !',
      description:
        'Trustable and Easy to use ticketing platform in UK. Plus their fees are absolutely low. Go for it !',
      linkToReview: 'https://www.trustpilot.com/reviews/6692019f1fdcca15132e5a3f',
      user: {
        fullName: `Pratik Khanal`,
        stars: 5,
      },
    },
    {
      id: 4,
      heading: 'Great Service',
      description:
        'Great service provided at a decent price! Loved how easy it is to buy tickets.Highly recommended.',
      linkToReview: 'https://www.trustpilot.com/reviews/66958f79539bbad0b6d3b1ca',
      user: {
        fullName: 'Christopher Hunt',
        stars: 5,
      },
    },
  ],
}

export default Testimonials
