const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FB_APIKEY || 'mock_key',
  authDomain: process.env.NEXT_PUBLIC_FB_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
}

export const PATH_GENERATION_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 1000 : 30
export const FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 100 : 30
export const SALES_ORDERS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50
export const EVENT_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 50 : 10
export const GUEST_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50

export const COMMENT_LIST_PAGINATION_FETCH_LIMIT = 2
export const CANCELLATION_LIST_PAGINATION_FETCH_LIMIT = 'production' ? 500 : 50

export const MINIMAL_COMMENT_LIST_PAGINATION_FETCH_LIMIT = 3

export default FIREBASE_CONFIG
