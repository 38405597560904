import MSG from 'consts/Messages'
import {
  arrayRemove,
  arrayUnion,
  collection, //   deleteDoc,
  doc, //   getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const createProduct = async (productId, payload) => {
  const docRef = doc(db, 'products', productId)

  try {
    await setDoc(docRef, { ...payload, createdAt: serverTimestamp() })
  } catch (err) {
    console.log('Error creating duplicate product', err)
    throw err
  }
}

export const updateProduct = async (
  productId,
  payload,
  callback = () => {},
  loading = {
    start: () => {},
    remove: () => {},
  }
) => {
  try {
    loading.start()
    const docRef = doc(db, 'products', productId)
    await updateDoc(docRef, payload)
    callback()
  } catch (error) {
    console.error('Error updating product in database', error)
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'operation-failed' })
  } finally {
    loading.remove()
  }
}

//read product by hostId
export const readProductsByHostId = async (hostId, callback = () => {}) => {
  if (!hostId) return null

  const productRef = query(collection(db, 'products'), where('hostId', '==', hostId))

  try {
    onSnapshot(productRef, querySnapshot => {
      const products = querySnapshot.docs.map(doc => doc.data())
      callback(products)
    })
  } catch (error) {
    console.error('Error @fetchAllPublicProducts', error)
  }
}

export const readProductByIdwRTU = async (productId, callback) => {
  if (!productId) return
  try {
    const q = query(collection(db, 'products'), where('productId', '==', productId))
    onSnapshot(q, snapshot => callback(snapshot.docs[0]?.data()))
  } catch (error) {
    console.error('Error fetching product data:', error)
    toast.error(MSG.ERROR.BUYER.FETCH_PRODUCT_DETAILS, { toastId: 'error-fetching-details' })
  }
}

//survey functions

export const addSurveyQuestion = async (productId, formInput, callback = () => {}) => {
  if (!productId) return
  try {
    const docRef = doc(db, 'products', productId)
    await updateDoc(docRef, { survey: arrayUnion(formInput) })
    callback()
    console.log('Question added successfully')
  } catch (error) {
    toast.error('Error adding question to survey', error)
  }
}

export const removeSurveyQuestion = async (productId, question) => {
  if (!productId) return
  try {
    const docRef = doc(db, 'products', productId)
    await updateDoc(docRef, { survey: arrayRemove(question) })
    console.log('Question removed successfully')
  } catch (error) {
    toast.error('Error removing question from survey', error)
  }
}
