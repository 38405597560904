import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import { BiChevronRight } from '@react-icons/all-files/bi/BiChevronRight'
import { SiTrustpilot } from '@react-icons/all-files/si/SiTrustpilot'
import ReusableGrid from 'components/containers/ReusableGrid'
import Heading from 'components/molecules/Heading'
import testimonials from 'consts/Testimonials.js'
import Link from 'next/link'
import React, { useEffect, useRef, useState } from 'react'

const Testimonials = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const carouselRef = useRef(null)

  const handleScroll = direction => {
    const container = carouselRef.current
    if (container) {
      const scrollAmount = container.clientWidth
      const maxScroll = container.scrollWidth - container.clientWidth

      let newPosition = scrollPosition + (direction === 'next' ? scrollAmount : -scrollAmount)
      newPosition = Math.max(0, Math.min(newPosition, maxScroll))

      container.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      })
      setScrollPosition(newPosition)
    }
  }

  useEffect(() => {
    const container = carouselRef.current
    if (container) {
      setIsAtStart(scrollPosition === 0)
      setIsAtEnd(scrollPosition + container.clientWidth >= container.scrollWidth)
    }
  }, [scrollPosition])

  return (
    <ReusableGrid className='rounded-xl bg-base-200 p-4 md:p-16' id='testimonials'>
      <div className='col-span-full mb-8 flex items-center justify-center text-center'>
        <Heading title={testimonials.title} heroHeading={true} className='mx-auto' />
      </div>

      <div className='relative col-span-full'>
        <div ref={carouselRef} className='carousel flex gap-4 overflow-x-hidden scroll-smooth '>
          {testimonials.testimonials.map(testimonial => (
            <Link
              key={testimonial.id}
              href={testimonial.linkToReview}
              className='h-full w-full flex-none md:w-1/2 lg:w-1/3'
              passHref
              target='_blank'>
              <div className='custom-transtion-a m-2 flex h-full flex-col rounded-xl bg-base-100 p-8 duration-300 hover:shadow-lg'>
                <Heading
                  postTitle={testimonial.heading}
                  className='mb-4 text-center text-xl font-semibold'
                />
                <p className='mb-6 flex-grow text-center text-lg font-semibold'>
                  "{testimonial.description}"
                </p>
                <div className='mt-auto flex flex-col items-center gap-3'>
                  <p className='font-semibold'>{testimonial.user.fullName}</p>
                  <div className='flex'>
                    {Array.from({ length: testimonial.user.stars }, (_, index) => index + 1).map(
                      i => (
                        <div key={i} className='m-1 bg-[#00b67a] p-1'>
                          <SiTrustpilot color='white' size={20} />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <button
          onClick={() => handleScroll('prev')}
          disabled={isAtStart}
          className='absolute -left-3 top-1/2 -translate-y-1/2 transform rounded-full bg-[#00b67a] p-2 disabled:opacity-50'>
          <BiChevronLeft size={36} color='white' />
        </button>
        <button
          onClick={() => handleScroll('next')}
          disabled={isAtEnd}
          className='absolute -right-3 top-1/2 -translate-y-1/2 transform rounded-full bg-[#00b67a] p-2 disabled:opacity-50'>
          <BiChevronRight size={36} color='white' />
        </button>
      </div>
    </ReusableGrid>
  )
}

export default Testimonials
