import ROUTES from 'consts/Routes'

import { BOOK_A_MEETING_WITH_FOUNDERS_URL } from './Urls'

const steps = {
  title1: 'Ready to Go?',
  cta1: {
    title: 'Sign Up Now',
    href: ROUTES.SIGN_UP,
    external: false,
  },
  desc1:
    'Create your account in minutes and start listing your events. Self-starters like you need no encouragement.',
  title2: 'Need Some Help?',
  cta2: {
    title: 'Book a meeting',
    href: BOOK_A_MEETING_WITH_FOUNDERS_URL,
    external: true,
  },
  desc2: `Organise a time for one of our platform professionals to speak with you and get you set up. There’s no such thing as a stupid question.
`,
}
export default steps
