import DynamicCTA from 'components/atoms/DynamicCta'
import ReusableGrid from 'components/containers/ReusableGrid'
import Description from 'components/molecules/Description'
import Heading from 'components/molecules/Heading'
import steps from 'consts/ReadyToStart.js'
import React from 'react'

const ReadyToStart = () => {
  return (
    <ReusableGrid className='rounded-xl p-4 md:!p-16 ' id='ready-to-start'>
      <div className='custom-transition-a col-span-full flex flex-col items-center gap-4 rounded-xl px-1 py-3 text-center hover:cursor-pointer hover:bg-secondary hover:text-secondary-content md:col-span-6'>
        <Heading title={steps.title1} className='mx-auto' />
        <Description text={steps?.desc1} className='text-lg' />
        <DynamicCTA cta={steps.cta1} className='mb-1 mt-7' />
      </div>
      <div className='custom-transition-a col-span-full flex flex-col items-center  gap-4 rounded-xl p-2 text-center hover:cursor-pointer hover:bg-primary hover:text-secondary-content md:col-span-6'>
        <Heading title={steps.title2} className='mx-auto' />
        <Description text={steps?.desc2} className='text-lg' />
        <DynamicCTA cta={steps.cta2} />
      </div>
    </ReusableGrid>
  )
}

export default ReadyToStart
