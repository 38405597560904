import cn from 'clsx'
import Button from 'components/atoms/Button'
import MSG from 'consts/Messages'
import ROUTES from 'consts/Routes'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { createEventViaURL } from 'services/client'

interface EventFormData {
  url: string
  consent: boolean
}

const EventCreationViaURLModal = () => {
  const router = useRouter()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<EventFormData>({
    mode: 'onChange',
  })

  const [isCreating, setIsCreating] = useState(false)

  const onSubmit: SubmitHandler<EventFormData> = async data => {
    setIsCreating(true)
    try {
      const newEvent = await createEventViaURL(data?.url)

      toast.success(MSG.SUCCESS.SELLER.EVENT.CREATED, {
        toastId: 'event-creation-via-url-successful',
      })
      const dialog = document.getElementById(
        'event-creation-via-url-modal'
      ) as HTMLDialogElement | null
      dialog?.close()
      router.push({
        pathname: ROUTES.EVENT_OVERVIEW.replace(':eventId', newEvent?.eventId),
        query: { redirectFrom: 'event-creation-modal' },
      })

      reset()
    } catch (err) {
      toast.error(err.message, { toastId: 'event-creation-via-url-error' })
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <dialog id='event-creation-via-url-modal' className='modal'>
      <div className='modal-box'>
        <form method='dialog'>
          <button className='btn btn-circle btn-ghost btn-sm absolute right-2 top-2'>✕</button>
        </form>

        <header>
          <h3 className='mb-4 text-lg font-bold'>Create Event Using URL</h3>
        </header>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
          <section className='flex flex-col items-start justify-center gap-2'>
            <label htmlFor='url' className='block text-sm font-medium'>
              Enter the link to your event
            </label>

            <input
              id='url'
              type='text'
              placeholder='Enter a valid URL'
              {...register('url', {
                required: 'URL is required',
                pattern: {
                  value:
                    /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(:[0-9]{1,5})?(\/[\w .-]*)*\/?(#[\w-]*)?(\?[&\w.-=]*)?$/i,
                  message: 'Please enter a valid URL',
                },
              })}
              className={cn('input input-bordered w-full', errors.url && 'input-error')}
            />

            {errors.url && <p className='text-sm text-error'>{errors.url.message}</p>}
          </section>

          <section className='flex flex-col items-start justify-center gap-2'>
            <div className='flex items-center gap-2'>
              <input
                id='consent'
                type='checkbox'
                {...register('consent', { required: 'You must provide consent' })}
                className={cn('checkbox checkbox-sm', errors.consent && 'checkbox-error')}
              />

              <label htmlFor='consent' className='text-sm font-medium'>
                I consent to processing data from the provided URL.
              </label>
            </div>

            {errors.consent && <p className='text-sm text-error'>{errors.consent.message}</p>}
          </section>

          <footer>
            <Button type='submit' disabled={!isValid || isCreating} className='w-full' primary>
              {isCreating ? (
                <div className='flex items-center gap-1'>
                  <span>Creating</span>
                  <span className='loading loading-spinner loading-xs' />
                </div>
              ) : (
                'Create'
              )}
            </Button>
          </footer>
        </form>
      </div>
    </dialog>
  )
}

export default EventCreationViaURLModal
