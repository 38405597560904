import { CLOUD_FUNCTIONS } from 'consts/CloudFunctions'
import { FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { getToken } from 'services/client/common/token'
import getRequestOptions from 'utils/Headers'
import { getCutOffTimestamp } from 'utils/Timestamp'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################

export const createEventViaURL = async eventUrl => {
  if (!eventUrl) return

  try {
    const accessToken = await getToken()

    if (!accessToken) {
      console.error('Failed to retrieve access token!')
      throw new Error(MSG.ERROR.SELLER.ACCESS_TOKEN_EXPIRED)
    }

    const requestOptions = getRequestOptions({ eventUrl }, 'POST', 'follow', accessToken)
    const cfURL = CLOUD_FUNCTIONS.SCRAPE_EVENT

    const response = await fetch(cfURL, requestOptions)
    const parsedResponse = await response.json()

    if (!response.ok) {
      throw new Error(parsedResponse.message)
    }

    return parsedResponse
  } catch (error) {
    console.error('An error occurred while creating the event via URL:', error)
    throw new Error(error.message || MSG.ERROR.OPERATION_FAILED)
  }
}

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readPublicEvents = async (callback = () => {}) => {
  const cutOffLastThreeDays = getCutOffTimestamp('past', 3)
  const ref = query(
    collection(db, 'events'),
    where('isPublished', '==', true),
    where('isPrivate', '==', false),
    where('date', '>=', cutOffLastThreeDays),
    orderBy('date', 'asc'),
    limit(FETCH_LIMIT)
  )

  try {
    const snapshot = await getDocs(ref)
    const events = snapshot.docs.map(doc => doc.data())
    callback(events)
    return events
  } catch (error) {
    toast.error(MSG.ERROR.OPERATION_FAILED, { toastId: 'duplication-error' })
    console.error('Error @fetchAllPublicEvents', error)
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################
