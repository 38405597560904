// ------------- UI Reducer -------------
import { createSlice} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const todayDate = new Date()
const startDate = new Date(todayDate)
startDate.setDate(todayDate.getDate() - 29)

const endDate = new Date(todayDate)

const initialState = {
  viewedAlertsTimeStamps: [],
  activeNav: 'Home',
  isDrawerOpen: true,
  onboarding: false,
  titleBar: {},
  megaMenu: false,
  pwaRoute: 'home',
  // userBackChevron: null,
  showDebug: false,
  signUp: false,
  platform: 'unknown',
  appUI: false,
  searchQuery: '',
  theme: 'light',
  notifications: [{}],
  visitorIP: '',
  visitorIPCountry: 'GB',
  exchange: {},
  exchangeTimeStamp: new Date().setHours(new Date().getHours() - 2),
  basketStore: null,
  ticketStore: null,
  isScannerForCurrentEventPage: false,
  marketingEmail: {},
  trafficSource: 'Sessami',
  scrollPosition: 0,
  activeModalName: '',
  pricingModalDetails: {},
  wantsToJoinWaitList: false,
  currentEventDetails: {},
  currentProductDetails: {},
  isCookieSet: false,
  cookie: {
    necessary: true,
    performance: false,
    statistics: false,
  },
  charts: {
    salesByDate: {
      viewChartOf: 'Net Revenue',
      date: [
        {
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        },
      ],
    },
  },
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    markAlertsAsViewed: (state, action) => {
      // Make sure that we don't again store the saved timestamp if the state already has it! (This won't ever happen via the UI, but preventing here for the sake of completeness!)
      if (state?.viewedAlertsTimeStamps?.includes(action?.payload)) return state
      return {
        ...state,
        viewedAlertsTimeStamps: [...(state?.viewedAlertsTimeStamps ?? ''), action?.payload],
      }
    },
    setChartsSelection: (state, action) => ({ ...state, charts: action?.payload }),
    setActiveNav: (state, action) => ({ ...state, activeNav: action?.payload || '' }),
    setCookie: (state, action) => ({
      ...state,
      cookie: action?.payload,
    }),
    setDrawer: (state, action) => ({ ...state, isDrawerOpen: action?.payload }),
    setOnboarding: (state, action) => ({ ...state, onboarding: action?.payload }),
    setIsCookieSet: (state, action) => ({ ...state, isCookieSet: action?.payload }),
    setTitleBar: (state, action) => ({ ...state, titleBar: action?.payload }),
    setShowDebug: (state, action) => ({ ...state, showDebug: action?.payload }),
    setPwaRoute: (state, action) => ({ ...state, pwaRoute: action?.payload }),
    setMegaMenu: (state, action) => ({ ...state, megaMenu: action?.payload }),
    // setUserBackChevron: (state, action) => ({ ...state, userBackChevron: action?.payload }),
    setSignUp: (state, action) => ({ ...state, signUp: action?.payload }),

    setPlatform: (state, action) => ({ ...state, platform: action?.payload }),
    setAppUI: (state, action) => ({ ...state, appUI: action?.payload }),

    setSearchQuery: (state, action) => ({ ...state, searchQuery: action?.payload }),
    setTheme: (state, action) => ({ ...state, theme: action?.payload }),
    setNotifications: (state, action) => ({ ...state, notifications: action?.payload }),

    setVisitorIPCountry: (state, action) => ({ ...state, visitorIPCountry: action?.payload }),
    setVisitorIP: (state, action) => ({ ...state, visitorIP: action?.payload }),
    setExchange: (state, action) => ({ ...state, exchange: action?.payload }),

    setBasketStore: (state, action) => ({ ...state, basketStore: action?.payload }),
    setTicketStore: (state, action) => ({ ...state, ticketStore: action?.payload }),
    setScrollPosition: (state, action) => ({ ...state, scrollPosition: action?.payload }),
    setMarketingEmail: (state, action) => ({ ...state, marketingEmail: action?.payload }),
    setIsScannerForCurrentEventPage: (state, action) => ({
      ...state,
      isScannerForCurrentEventPage: action?.payload,
    }),
    setTrafficSource: (state, action) => ({ ...state, trafficSource: action?.payload }),
    setActiveModalName: (state, action) => ({ ...state, activeModalName: action?.payload }),
    setPricingModalDetails: (state, action) => ({ ...state, pricingModalDetails: action?.payload }),
    setWantsToJoinWaitList: (state, action) => ({ ...state, wantsToJoinWaitList: action?.payload }),

    setCurrentEventDetails: (state, action) => ({ ...state, currentEventDetails: action?.payload }),
    setCurrentProductDetails: (state, action) => ({
      ...state,
      currentProductDetails: action?.payload,
    }),
    // resetUI: state => ({ ...initialState, viewedAlerts: state.viewedAlerts }),
    resetUI: () => initialState,
  },

  // Special reducer for hydrating the state
  // extraReducers: {
  //   [HYDRATE]: (state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.comments,
  //     }
  //   },
  // },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  markAlertsAsViewed,
  setActiveNav,
  setChartsSelection,
  setDrawer,
  setOnboarding,
  setTitleBar,
  setPlatform,
  setAppUI,
  setPwaRoute,
  setShowDebug,
  setMarketingEmail,
  resetUI,
  setMegaMenu,
  setTheme,
  setIsCookieSet,
  setSignUp,
  setCookie,
  setScrollPosition,
  setSearchQuery,
  setNotifications,
  setVisitorIPCountry,
  setVisitorIP,
  setExchange,
  setExchangeTimeStamp,
  setBasketStore,
  setTicketStore,
  setIsScannerForCurrentEventPage,
  setTrafficSource,
  setActiveModalName,
  setPricingModalDetails,
  setWantsToJoinWaitList,
  setCurrentEventDetails,
  setCurrentProductDetails,
} = uiSlice.actions

export default uiSlice.reducer
