import useGlobalLoader from 'hooks/useGlobalLoader'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSpecificDateOrdersBySellerId,
  readEventsByHostId,
  readOrdersBySellerId,
  readProductsByHostId,
} from 'services/client'
import { readCampaignsByHostId } from 'services/client/seller/campaigns'
import { setEvents, setMyCampaigns, setProducts, setSaleOrders } from 'store/reducers'

export default function useFetcherForHost() {
  const dispatch = useDispatch()
  const hostId = useSelector(s => s?.pr?.user?.info?.id)
  const { loading } = useGlobalLoader()

  const fetchAllMyEvents = () => {
    loading.start()
    readEventsByHostId(hostId, events => {
      dispatch(setEvents(events))
      loading.remove()
    })
  }

  const fetchAllMyEventOrders = async () => {
    readOrdersBySellerId(hostId, orders => {
      dispatch(setSaleOrders(orders))
    })
  }

  const fetchMySpecificDateOrders = async (startDate, endDate) => {
    const orders = await getSpecificDateOrdersBySellerId(hostId, startDate, endDate)
    return orders
  }

  const fetchMyCampaigns = () => {
    readCampaignsByHostId(hostId, campaigns => {
      dispatch(setMyCampaigns(campaigns))
    })
    // try {
    //   const campaigns = await new Promise(resolve => {
    //     readCampaignsByHostId(hostId, campaigns => {
    //       resolve(campaigns)
    //     })
    //   })
    //   return campaigns
    // } catch (error) {
    //   throw new Error('Error fetching campaigns: ' + error)
    // }
  }

  const fetchMyProducts = () => {
    readProductsByHostId(hostId, products => {
      dispatch(setProducts(products))
    })
  }

  return {
    fetchAllMyEvents,
    fetchAllMyEventOrders,
    fetchMySpecificDateOrders,
    fetchMyCampaigns,
    fetchMyProducts,
  }
}
