import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { BiChevronLeft } from '@react-icons/all-files/bi/BiChevronLeft'
import { BsCalendar2EventFill } from '@react-icons/all-files/bs/BsCalendar2EventFill'
import { BsMegaphoneFill } from '@react-icons/all-files/bs/BsMegaphoneFill'
import { FiSettings } from '@react-icons/all-files/fi/FiSettings'
import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { MdOutlineCardMembership } from '@react-icons/all-files/md/MdOutlineCardMembership'
import cn from 'clsx'
import Button from 'components/atoms/Button'
import withErrorBoundary from 'components/templates/WithErrorBoundary'
import {
  IAnalytics,
  ICancel,
  IClock,
  IComments,
  IDiscount,
  IHamburgerMenu,
  ITag,
  ITakeMyMoney,
  IVenues,
} from 'consts/Icons'
import ROUTES from 'consts/Routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawer, setMegaMenu } from 'store/reducers'

const NavDrawer = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const isDrawerOpen = useSelector(s => s?.pr?.ui?.isDrawerOpen)
  const megaMenu = useSelector(s => s?.pr?.ui?.megaMenu)

  const [activeParentNav, setActiveParentNav] = useState('')

  useEffect(() => {
    dispatch(setDrawer(true))
  }, [])

  useEffect(() => {
    dispatch(setMegaMenu(false))
  }, [router.pathname])

  const toggleMenu = () => {
    dispatch(setMegaMenu(false))
    dispatch(setDrawer(!isDrawerOpen))
  }

  const NavConfig = [
    { label: 'Home', icon: <AiFillHome size={20} />, path: ROUTES.DASHBOARD },
    {
      label: 'Orders',
      icon: <ITakeMyMoney size={20} />,
      path: ROUTES.REPORTS_OVERVIEW,
      subNav: [
        {
          label: 'Cancellations',
          // icon: <IReturnDownForward size={20} />,
          icon: <ICancel size={20} />,
          path: ROUTES.CANCELLATIONS,
          newFeature: true,
        },
      ],
    },
    { label: 'Separator', path: 'uniqueKey1' },

    // Products & Services - Start
    {
      label: 'Events',
      icon: <BsCalendar2EventFill size={20} />,
      path: ROUTES.EVENTS,
      subNav: [
        // { label: 'All', icon: <BsCalendar2EventFill size={16} />, path: ROUTES.EVENTS },
        { label: 'My Venues', icon: <IVenues size={20} />, path: ROUTES.VENUES },
        {
          label: 'Comments',
          icon: <IComments size={20} />,
          path: ROUTES.COMMENTS,
          newFeature: true,
        },
        { label: 'Waitlists', icon: <IClock size={20} />, path: ROUTES.WAITLISTS },
      ],
    },
    {
      label: 'Merch',
      icon: <ITag size={20} />,
      path: ROUTES.PRODUCTS,
      // newFeature: true,
      comingSoon: true,
    },
    {
      label: 'Memberships',
      icon: <MdOutlineCardMembership size={24} />,
      path: ROUTES.MEMBERSHIPS,
      comingSoon: true,
    },
    // Products & Services - END
    { label: 'Separator', path: 'uniqueKey2' },
    { label: 'Customers', icon: <FiUsers size={20} />, path: ROUTES.GUESTS },
    { label: 'Analytics', icon: <IAnalytics size={24} />, path: ROUTES.EARNINGS },
    { label: 'Marketing', icon: <BsMegaphoneFill size={20} />, path: ROUTES.PROMOTE },
    { label: 'Discounts', icon: <IDiscount size={20} />, path: ROUTES.DISCOUNTS, comingSoon: true },

    // Setting Option is Fixed at Bottom
  ]

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-50',
        'flex h-screen flex-col justify-between bg-neutral p-4 lg:flex',
        megaMenu ? 'w-full overflow-x-hidden shadow-xl' : 'hidden',
        isDrawerOpen ? 'lg:w-72' : 'lg:w-20',
        'custom-transition-a',
        '!dark:bg-base-200 bg-neutral'
      )}>
      <Button onClick={toggleMenu}>
        {isDrawerOpen ? (
          <BiChevronLeft size={36} className='justify-self-end' />
        ) : (
          <IHamburgerMenu size={24} className='' />
        )}
      </Button>

      <div className='z-50 flex flex-1 flex-col justify-center space-y-2'>
        {NavConfig.map(item => {
          if (item.label === 'Separator')
            return <div key={item.path} className='h-px w-full bg-white bg-opacity-10'></div>

          return (
            <>
              <Link
                key={item.label}
                href={item?.path || '#'}
                className={cn(
                  'flex items-center justify-start rounded-lg text-white',
                  'custom-transition-b cursor-pointer',
                  'hover:scale-105 hover:bg-secondary',
                  isDrawerOpen ? 'px-4 py-3' : 'tooltip tooltip-right tooltip-secondary p-3',
                  router?.pathname.includes(item.path) ? 'bg-secondary' : ''
                )}
                data-tip={item.label}
                onClick={() => setActiveParentNav(item.path)}>
                {item?.icon}
                <h2 className={cn(' ml-6', isDrawerOpen ? 'block' : 'lg:hidden')}>{item.label}</h2>

                {isDrawerOpen && item?.newFeature ? (
                  <div className='badge badge-success ml-2'> New</div>
                ) : (
                  ''
                )}
                {isDrawerOpen && item?.comingSoon ? (
                  <div className='badge badge-info ml-2 overflow-hidden'>Upcoming</div>
                ) : (
                  ''
                )}
              </Link>
              {/* SubNav */}
              {activeParentNav === item.path && item.subNav && (
                <div
                  className={cn(
                    isDrawerOpen ? 'ml-6' : 'ml-0',
                    'flex flex-col items-start justify-start space-y-2'
                  )}>
                  {item.subNav.map(subItem => (
                    <Link
                      key={subItem.label}
                      href={subItem.path}
                      className={cn(
                        'flex w-full items-center justify-start rounded-lg text-white',
                        'custom-transition-b cursor-pointer',
                        'hover:scale-105 hover:bg-secondary',
                        isDrawerOpen ? 'px-2 py-2' : 'tooltip tooltip-right tooltip-secondary p-3',
                        router?.pathname.includes(subItem.path) ? 'bg-secondary' : ''
                      )}
                      data-tip={subItem.label}>
                      {subItem?.icon}
                      <h3 className={cn('ml-2 text-sm', isDrawerOpen ? 'block' : 'lg:hidden')}>
                        {subItem.label}
                      </h3>
                      {isDrawerOpen && subItem?.newFeature ? (
                        <div className='badge badge-success ml-2'>New</div>
                      ) : (
                        ''
                      )}
                      {isDrawerOpen && subItem?.comingSoon ? (
                        <div className='badge badge-info ml-2 overflow-hidden'>Upcoming</div>
                      ) : (
                        ''
                      )}
                    </Link>
                  ))}
                </div>
              )}
            </>
          )
        })}
      </div>

      <Link
        key='Settings'
        href={ROUTES.SETTINGS_PROFILE}
        className={cn(
          'flex items-center justify-start rounded-lg text-white',
          'custom-transition-b cursor-pointer',
          'hover:scale-105 hover:bg-secondary',
          isDrawerOpen ? 'px-4 py-3' : 'tooltip tooltip-right tooltip-secondary p-3',
          router?.pathname.includes(ROUTES.SETTINGS_PROFILE) ? 'bg-secondary' : ''
        )}
        data-tip='Settings'>
        <FiSettings size={20} />
        <h2 className={cn(' ml-6', isDrawerOpen ? 'block' : 'lg:hidden')}>Settings</h2>
      </Link>
    </div>
  )
}
export default withErrorBoundary(NavDrawer)
