import React from 'react'

import Container from './Container'
import GridContainer from './GridContainer'
import SectionContainer from './SectionContainer'

const ReusableGrid = ({ children, id = '', className = '', containerClassName = '' }) => {
  return (
    <SectionContainer id={id}>
      <Container className={containerClassName}>
        <GridContainer className={`${className}`}>{children}</GridContainer>
      </Container>
    </SectionContainer>
  )
}

export default ReusableGrid
