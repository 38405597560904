import DynamicCTA from 'components/atoms/DynamicCta'
import ReusableGrid from 'components/containers/ReusableGrid'
import ImageCarousel from 'components/molecules/ImageCarousel'
import { default as heroData } from 'content/Hero'
import React from 'react'

const LandingHero = () => {
  return (
    <ReusableGrid className='items-center py-6 md:py-10' id='landing-hero'>
      <div className='md:col-start-0 col-span-full flex flex-col items-center gap-5 md:col-span-5 md:items-start'>
        <h2 className='text-3xl font-bold lg:text-6xl'>{heroData.title}</h2>
        <h3 className='font-semiBold text-xl lg:text-3xl'>{heroData.subTitle}</h3>

        <div className='flex gap-2 md:gap-4'>
          <DynamicCTA cta={heroData.cta1} />
          <DynamicCTA cta={heroData.cta2} />
        </div>
      </div>
      <div className='order-first col-span-full md:order-none md:col-span-7 md:col-start-5'>
        {/* <Image src={heroData.image} height={900} width={1600} className=' rounded-lg' alt='image' /> */}
        <ImageCarousel images={heroData.images} imageWidth={800} />
      </div>
    </ReusableGrid>
  )
}

export default LandingHero
