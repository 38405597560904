import { AiOutlineSmile } from '@react-icons/all-files/ai/AiOutlineSmile'
import { BsHourglassSplit } from '@react-icons/all-files/bs/BsHourglassSplit'
import { GiTakeMyMoney } from '@react-icons/all-files/gi/GiTakeMyMoney'

// import { IoDiamond } from '@react-icons/all-files/io5/IoDiamond'

const problemSolving = {
  title: 'How does SESSAMI solve these problems?',
  solutions: [
    {
      id: 1,
      heading: 'Insights & Analytics',
      subHeading: `Our user-friendly dashboard makes it easy to see where your customers are coming from. Create custom UTM parameters to understand which channel is working best, and focus your marketing efforts where they’ll have the greatest impact.`,
      icon: <GiTakeMyMoney size={52} className='text-success' />,
      image:
        'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/gallery%2F54040209-284e-481e-9e75-5c1db3265e52?alt=media&token=3b7f3390-e9f0-4e3b-8fab-ba8de1892ad8',
    },
    {
      id: 2,
      heading: 'Easy-to-Use & Cost Effective',
      subHeading: `We built SESSAMI using customer feedback and users shape the on-going development of the platform. If it doesn’t work for you, it doesn’t work for us. SESSAMI is accessible for event managers at all levels of technical expertise. What makes this even better? We’re cost effective compared to the competition.`,
      icon: <AiOutlineSmile size={52} className='text-info' />,
      image:
        'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/gallery%2F358bd394-e19e-4d1e-b8b8-9e3e89ef61c9?alt=media&token=6815904d-b73f-46d0-8f79-dd860b1b045d',
    },
    {
      id: 3,
      heading: 'Personal Customer Service',
      subHeading: `SESSAMI’s customers are real people, and so are our customer service team. If you have an issue, drop us a line, and one of our colleagues will be in touch. No ifs, no buts.`,
      icon: <BsHourglassSplit size={52} className='text-error' />,
      image:
        'https://firebasestorage.googleapis.com/v0/b/sessami-production.appspot.com/o/gallery%2F46b9c139-9d26-411c-b66f-6fa038701f33?alt=media&token=6d168afd-5429-40cc-ac08-5af12ed51cfe',
    },
  ],
}

export default problemSolving
