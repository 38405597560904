import { IoMdCheckmark } from '@react-icons/all-files/io/IoMdCheckmark'

const achievements = {
  title: "Here's what will you get",
  achievements: [
    {
      id: 1,
      heading: 'Affordable Fees',
      subHeading: 'For every sale, we take 6% + £0.20.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 2,
      heading: 'Marketing and Sales Support',
      subHeading:
        'Know what is selling and where. Contact punters from within SESSAMI and relay key event information.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 3,
      heading: 'Speedy Checkout',
      subHeading: 'A simple 3-step process for punters to buy tickets.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 4,
      heading: 'Easy Crowd Management',
      subHeading:
        'Use the mobile app to scan tickets when guests arrive and manage the venue effectively.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 5,
      heading: 'AI Support',
      subHeading: 'Write compelling event titles and descriptions with our built-in AI features.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    {
      id: 6,
      heading: 'Customer-Friendly Event Pages',
      subHeading:
        'Encourage punters to buy tickets and packages with our data-backed UX page layouts and design.',
      icon: <IoMdCheckmark size={24} className='text-success' />,
    },
    // {
    //   id: 7,
    //   heading: 'Analytics',
    //   subHeading: 'Unpack customer behavior with ease',
    //   icon: <IoMdCheckmark size={24} className='text-success' />,
    // },
    // {
    //   id: 8,
    //   heading: 'Mobile First Design',
    //   subHeading: 'Optimal on mobile, sleek on desktop',
    //   icon: <IoMdCheckmark size={24} className='text-success' />,
    // },
    // {
    //   id: 9,
    //   heading: 'Automagic SEO',
    //   subHeading: 'SEO that effortlessly works for you',
    //   icon: <IoMdCheckmark size={24} className='text-success' />,
    // },
    // {
    //   id: 10,
    //   heading: 'AI Tools & Automations',
    //   subHeading: 'Embrace the future with our AI integrations',
    //   icon: <IoMdCheckmark size={24} className='text-success' />,
    // },
  ],
}

export default achievements
